<template>
  <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <div class="banner">
      <img :src="banners.image" alt="">
      <div class="w1440">
        <div class="txt" v-if="article!=''&&article!=null">
          <p class="fnt_42">{{ article.title }}</p>
          <span class="fnt_16">{{ article.subtitle }}</span>
        </div>
      </div>
    </div>
    <div class="refer_mine">
      <div class="refer_mineimg w1440">
        <template v-if="article!=''&&article!=null">
          <div class="refer_mine_tex fnt_42">{{ article.title }}</div>
          <div class="refer_mine_p fnt_16" v-html="article.content">
          </div>
        </template>

        <div style="text-align:center;" v-else>{{ globalDataArray.siteTemplateLanguage.system_data_empty_tips }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleDetail, getAdList } from '@/api/api'
import { message } from 'ant-design-vue'
export default {
  name: 'Singlepage',
  data() {
    return {
      title: this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteInfo.description,
      keywords: this.globalDataArray.siteInfo.keywords,
      id: this.$route.params.id,
      article: '',
      banners: ''
    }
  },
  watch: {
    $route(to, from) {
      this.id = this.$route.params.id
      this.init()
      if (typeof document === 'object') {
        document.documentElement.scrollTop = 0
      }
    }
  },
  mounted() {
    this.init()
    if (typeof document === 'object') {
      document.documentElement.scrollTop = 0
    }
  },
  methods: {
    init() {
      const that = this
      const params = 'id=' + that.id
      getArticleDetail(params).then(res => {
        if (res.success) {
          that.article = res.result

          if (that.article.title != '' && that.article.title != null) {
            that.title = that.article.title + '-' + that.globalDataArray.siteInfo.title
          }
          if (that.article.keywords != '' && that.article.keywords != null) {
            that.keywords = that.article.keywords
          }
          if (that.article.description != '' && that.article.description != null) {
            that.description = that.article.description
          }
        }
      })

      const adParam = 'alias=article_banner'
      getAdList(adParam).then(res => {
        if (res.success) {
          that.banners = res.result[0]
        }
      })
    },

    backFun() {
      this.$router.go(-1)
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.description },
        { vmid: 'keywords', name: 'keywords', content: this.keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/entry_Information.css'
</style>
